<script>
import PodcastItem from "../podcasts/PodcastItem.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    PodcastItem
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          link: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    }
  },
};
</script>

<template>
  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
    <h4 class="mb-sm-0">{{ $t('t-homepage-header-top-podcasts') }}</h4>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="team-list grid-view-filter row">
        <div class="col" v-for="p in data.podcasts" v-bind:key="p">
          <PodcastItem v-bind:data="p"></PodcastItem>
        </div>
      </div>
    </div>
  </div>
</template>
