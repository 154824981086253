<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
};
</script>

<template>
  <div class="row mt-3" v-if="data.banners">
    <div class="col-xxl-6 col-lg-6" v-for="p in data.banners" v-bind:key="p">
      <div class="card card-overlay">
        <img class="card-img img-fluid" :src="p.urlImage" alt="">
        <div class="card-img-overlay p-0 d-flex flex-column">
          <div class="card-header bg-transparent">
            <h4 class="card-title text-white mb-0">
              <a :href="p.url">
                {{ p.title }}
              </a>
            </h4>
          </div>
          <div class="card-body">
            <p class="card-text text-white mb-2">{{ p.memo }}</p>
          </div>
          <div class="card-footer bg-transparent text-center">
            <a :href="p.url" class="link-light">
               {{ $t('t-homepage-header-banner-open')}} <i class="ri-arrow-right-s-line align-middle ms-1 lh-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
