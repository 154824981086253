<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
};
</script>

<template>
  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
    <h4 class="mb-sm-0">Last episodes</h4>
  </div>
  <div class="row">
    <div class="col-xxl-6">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-4"><img class="rounded-start img-fluid h-100 object-cover" src="/img/img-12.1553c2b0.jpg"
              alt="Card image"></div>
          <div class="col-md-8">
            <div class="card-header">
              <h5 class="card-title mb-0">Give your text a good structure</h5>
            </div>
            <div class="card-body">
              <p class="card-text mb-2">For that very reason, I went on a quest and spoke to many different
                professional graphic designers and asked them what graphic design tips they live.</p>
              <p class="card-text">
                <small class="text-muted">Last updated 3 mins ago </small>
                <small class="text-muted fs-12 mb-0">Nov 22, 2021 <i
                    class="mdi mdi-circle-medium align-middle mx-1"></i>11:47 AM</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-6">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-header">
              <h5 class="card-title mb-0">Manage white space in responsive layouts ?</h5>
            </div>
            <div class="card-body">
              <p class="card-text mb-2">For that very reason, I went on a quest and spoke to many different
                professional graphic designers and asked them what graphic design tips they live.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="col-md-4"><img class="rounded-end img-fluid h-100 object-cover" src="/img/img-4.594891eb.jpg"
              alt="Card image"></div>
        </div>
      </div>
    </div>
    <div class="col-xxl-6">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-4"><img class="rounded-start img-fluid h-100 object-cover" src="/img/img-9.434fdcc0.jpg"
              alt="Card image"></div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title mb-2">Give your text a good structure</h5>
              <p class="card-text text-muted mb-0">For that very reason, I went on a quest and spoke to many different
                professional graphic designers and asked them what graphic design tips they live.</p>
            </div>
            <div class="card-footer">
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-6">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title mb-2">Manage white space in responsive layouts ?</h5>
              <p class="card-text text-muted mb-0">For that very reason, I went on a quest and spoke to many different
                professional graphic designers and asked them what graphic design tips they live.</p>
            </div>
            <div class="card-footer">
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="col-md-4"><img class="rounded-end img-fluid h-100 object-cover" src="/img/img-2.72beffe2.jpg"
              alt="Card image"></div>
        </div>
      </div>
    </div>
  </div>
</template>
