<script>
export default {
  data() {
    return {
      data: {
      }
    };
  },
  components: {
  },
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-sm-12 mx-n4">
      
      <div class="card">
        <div class="card-body">
          <div class="bg-overlay bg-overlay-pattern p-0 m-0"></div>
          
          <div class="text-center mt-lg-5 pt-5">
            <h1 class="display-6 fw-semibold mb-3 lh-base">
              {{ $t('t-homepage-main-title') }}
            </h1>
            <p class="lead text-muted lh-base">
              {{ $t('t-homepage-main-description') }}
            </p>
            <div class="d-flex gap-2 justify-content-center mt-5 mb-2">
              <router-link to="/login" class="btn btn-primary">
                {{ $t('t-homepage-main-button-login') }} <i class="ri-arrow-right-line align-middle ms-1"></i>
              </router-link>
              <!-- <a href="/pages/pricing" class="btn btn-danger">
                View Plans <i class="ri-eye-line align-middle ms-1"></i>
              </a> -->
            </div>
          </div>

          <div class="pt-5">
            <img src="@/assets/images/art/group-podcast.png" class="figure-img img-fluid" />
          </div>

        </div>
      </div>

    </div>
  </div>
</template>