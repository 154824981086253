<script>
import SwiperCore, { Thumbs, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Pagination, Navigation]);

export default {
  data() {
    return {
      data: {
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
};
</script>

<template>
  <div class="col-lg-12">

    <!-- Swiper -->
    <swiper class="swiper responsive-swiper rounded gallery-light pb-4" :loop="true" :slidesPerView="1"
      :spaceBetween="10" :pagination="{
        el: '.swiper-pagination',
        clickable: true,
      }" :breakpoints="{
  640: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 50,
  },
}">
      <swiper-slide>
        <div class="gallery-box card">
          <div class="gallery-container">
            <a class="image-popup" href="assets/images/small/img-1.jpg" title>
              <img class="gallery-img img-fluid mx-auto" src="@/assets/images/small/img-1.jpg" alt />
              <div class="gallery-overlay">
                <h5 class="overlay-caption">Glasses and laptop from above</h5>
              </div>
            </a>
          </div>
          <div class="box-content">
            <div class="d-flex align-items-center mt-1">
              <div class="flex-grow-1 text-muted">
                by
                <a href class="text-body text-truncate">Ron Mackie</a>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-3">
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-thumb-up-fill text-muted align-bottom me-1"></i>
                    2.2K
                  </button>
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-question-answer-fill text-muted align-bottom me-1"></i>
                    1.3K
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="gallery-box card">
          <div class="gallery-container">
            <a class="image-popup" href="assets/images/small/img-2.jpg" title>
              <img class="gallery-img img-fluid mx-auto" src="@/assets/images/small/img-2.jpg" alt />
              <div class="gallery-overlay">
                <h5 class="overlay-caption">Working at a coffee shop</h5>
              </div>
            </a>
          </div>
          <div class="box-content">
            <div class="d-flex align-items-center mt-1">
              <div class="flex-grow-1 text-muted">
                by
                <a href class="text-body text-truncate">Nancy Martino</a>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-3">
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-thumb-up-fill text-muted align-bottom me-1"></i>
                    3.2K
                  </button>
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-question-answer-fill text-muted align-bottom me-1"></i>
                    1.1K
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="gallery-box card mb-0">
          <div class="gallery-container">
            <a class="image-popup" href="assets/images/small/img-10.jpg" title>
              <img class="gallery-img img-fluid mx-auto" src="@/assets/images/small/img-10.jpg" alt />
              <div class="gallery-overlay">
                <h5 class="overlay-caption">Fun day at the Hill Station</h5>
              </div>
            </a>
          </div>
          <div class="box-content">
            <div class="d-flex align-items-center mt-1">
              <div class="flex-grow-1 text-muted">
                by
                <a href class="text-body text-truncate">Henry Baird</a>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-3">
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-thumb-up-fill text-muted align-bottom me-1"></i>
                    632
                  </button>
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-question-answer-fill text-muted align-bottom me-1"></i>
                    95
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="gallery-box card">
          <div class="gallery-container">
            <a class="image-popup" href="assets/images/small/img-4.jpg" title>
              <img class="gallery-img img-fluid mx-auto" src="@/assets/images/small/img-4.jpg" alt />
              <div class="gallery-overlay">
                <h5 class="overlay-caption">Drawing a sketch</h5>
              </div>
            </a>
          </div>
          <div class="box-content">
            <div class="d-flex align-items-center mt-1">
              <div class="flex-grow-1 text-muted">
                by
                <a href class="text-body text-truncate">Jason McQuaid</a>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-3">
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-thumb-up-fill text-muted align-bottom me-1"></i>
                    825
                  </button>
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-question-answer-fill text-muted align-bottom me-1"></i>
                    101
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="gallery-box card">
          <div class="gallery-container">
            <a class="image-popup" href="assets/images/small/img-6.jpg" title>
              <img class="gallery-img img-fluid mx-auto" src="@/assets/images/small/img-6.jpg" alt />
              <div class="gallery-overlay">
                <h5 class="overlay-caption">Project discussion with team</h5>
              </div>
            </a>
          </div>
          <div class="box-content">
            <div class="d-flex align-items-center mt-1">
              <div class="flex-grow-1 text-muted">
                by
                <a href class="text-body text-truncate">Erica Kernan</a>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-3">
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-thumb-up-fill text-muted align-bottom me-1"></i>
                    3.4K
                  </button>
                  <button type="button" class="btn btn-sm fs-12 btn-link text-body text-decoration-none px-0">
                    <i class="ri-question-answer-fill text-muted align-bottom me-1"></i>
                    1.3k
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-dark"></div>
    </swiper>
    <!-- end card-body -->
  </div>
</template>